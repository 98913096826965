import { ContentContainer } from 'components/Container/index';
import { CrochetIcon } from 'components/Logo/CrochetIcon';

export const Logo = () => {
  return (
    <ContentContainer>
      <div className="flex items-center">
        <CrochetIcon />
        <span className="text-2xl font-600 ml-4 text-primary leading-9 xl:text-3xl">
          Crochet App
        </span>
      </div>
    </ContentContainer>
  );
};
