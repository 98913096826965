export const AppStoreIcon = () => {
  return (
    <svg
      className="w-33 h-10 lg:w-42 lg:h-13"
      viewBox="0 0 169 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M162.614 0H6.25439C2.80018 0 0 2.7982 0 6.24996V43.7497C0 47.2015 2.80018 49.9997 6.25439 49.9997H162.614C166.068 49.9997 168.868 47.2015 168.868 43.7497V6.24996C168.868 2.7982 166.068 0 162.614 0Z"
        fill="black"
      />
      <path
        d="M162.614 0.999988C164.007 1.0016 165.342 1.55527 166.327 2.53954C167.312 3.52381 167.866 4.85829 167.868 6.25021V43.75C167.866 45.1419 167.312 46.4764 166.327 47.4606C165.342 48.4449 164.007 48.9986 162.614 49.0002H6.25439C4.86147 48.9986 3.52607 48.4449 2.54116 47.4607C1.55624 46.4764 1.00224 45.1419 1.0007 43.75V6.25021C1.00222 4.85827 1.55621 3.52377 2.54113 2.5395C3.52605 1.55523 4.86146 1.00157 6.25439 0.999988H162.614ZM162.614 0.000244141H6.25439C4.59711 0.00509173 3.0091 0.665125 1.83723 1.83617C0.665351 3.00722 0.00485103 4.5941 0 6.25021L0 43.75C0.00485103 45.4061 0.665351 46.993 1.83723 48.164C3.0091 49.3351 4.59711 49.9951 6.25439 49.9999H162.614C164.271 49.9951 165.859 49.3351 167.031 48.164C168.203 46.993 168.864 45.4061 168.868 43.75V6.25021C168.864 4.5941 168.203 3.00722 167.031 1.83617C165.859 0.665125 164.271 0.00509173 162.614 0.000244141Z"
        fill="#A6A6A6"
      />
      <path
        d="M41.1151 23.5472C41.1286 22.5062 41.4073 21.4854 41.9252 20.5799C42.4431 19.6745 43.1834 18.9137 44.0772 18.3684C43.5094 17.5637 42.7603 16.9015 41.8894 16.4343C41.0186 15.9671 40.05 15.7079 39.0605 15.6772C36.9497 15.4573 34.9035 16.9305 33.8279 16.9305C32.7315 16.9305 31.0754 15.699 29.2922 15.7354C28.1387 15.7724 27.0145 16.1052 26.0292 16.7015C25.0439 17.2978 24.2311 18.1372 23.6699 19.1379C21.2389 23.3143 23.0522 29.4522 25.3809 32.8282C26.5459 34.4813 27.9076 36.3278 29.6892 36.2623C31.4327 36.1906 32.0839 35.1591 34.1884 35.1591C36.2734 35.1591 36.8843 36.2623 38.7021 36.2207C40.573 36.1905 41.7517 34.5602 42.8759 32.8915C43.713 31.7136 44.3571 30.4118 44.7845 29.0343C43.6976 28.5781 42.77 27.8145 42.1175 26.8388C41.465 25.863 41.1164 24.7183 41.1151 23.5472Z"
        fill="white"
      />
      <path
        d="M37.6817 13.457C38.7018 12.2419 39.2043 10.68 39.0826 9.10318C37.5242 9.2656 36.0847 10.0047 35.0508 11.1732C34.5454 11.744 34.1582 12.4081 33.9116 13.1275C33.6649 13.8469 33.5635 14.6075 33.6133 15.3658C34.3927 15.3738 35.1639 15.2061 35.8686 14.8755C36.5734 14.5448 37.1933 14.0598 37.6817 13.457Z"
        fill="white"
      />
      <path
        d="M63.1543 32.0774H57.2046L55.7758 36.2639H53.2557L58.8912 20.7751H61.5094L67.1449 36.2639H64.5818L63.1543 32.0774ZM57.8208 30.1456H62.5369L60.212 23.3513H60.147L57.8208 30.1456Z"
        fill="white"
      />
      <path
        d="M79.3156 30.6182C79.3156 34.1275 77.4228 36.3821 74.5664 36.3821C73.8428 36.4196 73.1232 36.2542 72.49 35.9048C71.8567 35.5554 71.3354 35.0361 70.9858 34.4064H70.9318V39.9997H68.5958V24.9713H70.8569V26.8496H70.8999C71.2656 26.2229 71.7957 25.7062 72.4338 25.3547C73.0718 25.0032 73.794 24.8298 74.5235 24.8532C77.4117 24.8532 79.3156 27.1188 79.3156 30.6182ZM76.9146 30.6182C76.9146 28.332 75.7239 26.8289 73.9072 26.8289C72.1225 26.8289 70.922 28.3636 70.922 30.6182C70.922 32.8936 72.1225 34.4174 73.9072 34.4174C75.7239 34.4174 76.9146 32.9252 76.9146 30.6182Z"
        fill="white"
      />
      <path
        d="M91.841 30.6182C91.841 34.1274 89.9482 36.3821 87.0918 36.3821C86.3682 36.4196 85.6486 36.2542 85.0154 35.9048C84.3821 35.5554 83.8608 35.0361 83.5112 34.4063H83.4571V39.9997H81.1212V24.9713H83.3823V26.8496H83.4252C83.7909 26.2229 84.321 25.7062 84.9591 25.3547C85.5972 25.0032 86.3194 24.8298 87.0488 24.8532C89.9371 24.8532 91.841 27.1188 91.841 30.6182ZM89.44 30.6182C89.44 28.332 88.2493 26.8289 86.4326 26.8289C84.6478 26.8289 83.4474 28.3636 83.4474 30.6182C83.4474 32.8936 84.6478 34.4173 86.4326 34.4173C88.2493 34.4173 89.44 32.9252 89.44 30.6182H89.44Z"
        fill="white"
      />
      <path
        d="M100.119 31.9485C100.292 33.4844 101.796 34.4929 103.851 34.4929C105.819 34.4929 107.236 33.4844 107.236 32.0994C107.236 30.8972 106.382 30.1774 104.359 29.684L102.336 29.2005C99.4697 28.5135 98.139 27.1834 98.139 25.025C98.139 22.3526 100.486 20.517 103.819 20.517C107.117 20.517 109.378 22.3526 109.454 25.025H107.096C106.955 23.4793 105.667 22.5463 103.785 22.5463C101.904 22.5463 100.616 23.4902 100.616 24.8642C100.616 25.9592 101.439 26.6036 103.45 27.0969L105.17 27.5159C108.373 28.2674 109.703 29.544 109.703 31.8095C109.703 34.7072 107.377 36.5221 103.677 36.5221C100.216 36.5221 97.8788 34.7499 97.7278 31.9483L100.119 31.9485Z"
        fill="white"
      />
      <path
        d="M114.745 22.2988V24.9712H116.909V26.8068H114.745V33.0323C114.745 33.9994 115.178 34.4501 116.13 34.4501C116.387 34.4457 116.643 34.4278 116.898 34.3965V36.2211C116.47 36.3004 116.036 36.3363 115.601 36.3283C113.297 36.3283 112.398 35.4696 112.398 33.2795V26.8068H110.743V24.9712H112.398V22.2988H114.745Z"
        fill="white"
      />
      <path
        d="M118.162 30.6181C118.162 27.0651 120.271 24.8324 123.559 24.8324C126.859 24.8324 128.958 27.065 128.958 30.6181C128.958 34.181 126.87 36.4039 123.559 36.4039C120.25 36.4039 118.162 34.1809 118.162 30.6181ZM126.578 30.6181C126.578 28.1808 125.452 26.7423 123.559 26.7423C121.667 26.7423 120.542 28.1918 120.542 30.6181C120.542 33.0652 121.667 34.4928 123.559 34.4928C125.452 34.4928 126.578 33.0652 126.578 30.6181H126.578Z"
        fill="white"
      />
      <path
        d="M130.884 24.9712H133.112V26.8933H133.166C133.317 26.293 133.671 25.7625 134.17 25.3909C134.668 25.0194 135.281 24.8295 135.904 24.8531C136.173 24.8521 136.441 24.8811 136.704 24.9396V27.1077C136.364 27.0046 136.01 26.9573 135.654 26.9676C135.315 26.954 134.977 27.0133 134.663 27.1417C134.349 27.27 134.066 27.4642 133.835 27.7111C133.604 27.9579 133.429 28.2514 133.323 28.5716C133.217 28.8917 133.182 29.2309 133.22 29.5657V36.2638H130.884L130.884 24.9712Z"
        fill="white"
      />
      <path
        d="M147.474 32.9471C147.16 34.997 145.148 36.4039 142.574 36.4039C139.263 36.4039 137.208 34.2029 137.208 30.6717C137.208 27.1296 139.274 24.8324 142.475 24.8324C145.624 24.8324 147.604 26.9786 147.604 30.4025V31.1967H139.566V31.3368C139.529 31.7524 139.582 32.1711 139.72 32.565C139.859 32.9589 140.08 33.3191 140.37 33.6217C140.659 33.9242 141.01 34.1621 141.399 34.3197C141.789 34.4772 142.207 34.5507 142.628 34.5354C143.18 34.5867 143.734 34.4599 144.207 34.1737C144.681 33.8875 145.048 33.4573 145.256 32.9471L147.474 32.9471ZM139.577 29.5767H145.267C145.288 29.203 145.231 28.8291 145.099 28.4783C144.968 28.1276 144.764 27.8076 144.502 27.5385C144.24 27.2694 143.925 27.0569 143.576 26.9144C143.227 26.772 142.853 26.7026 142.475 26.7106C142.095 26.7084 141.718 26.7809 141.366 26.924C141.014 27.0671 140.694 27.278 140.425 27.5445C140.155 27.8109 139.942 28.1277 139.796 28.4765C139.651 28.8253 139.576 29.1992 139.577 29.5767V29.5767Z"
        fill="white"
      />
      <path
        d="M57.5281 9.11651C58.0178 9.08163 58.5092 9.15505 58.9669 9.33146C59.4245 9.50786 59.8371 9.78287 60.1747 10.1366C60.5123 10.4904 60.7665 10.9141 60.9191 11.3772C61.0717 11.8403 61.1189 12.3312 61.0571 12.8145C61.0571 15.1922 59.7621 16.5589 57.5281 16.5589H54.819V9.11651H57.5281ZM55.9839 15.5064H57.3979C57.7479 15.5271 58.0981 15.4702 58.4231 15.3397C58.748 15.2092 59.0396 15.0085 59.2765 14.7521C59.5134 14.4957 59.6898 14.1901 59.7927 13.8575C59.8956 13.525 59.9224 13.1738 59.8713 12.8297C59.9187 12.4869 59.8891 12.1379 59.7847 11.8078C59.6802 11.4777 59.5034 11.1746 59.267 10.9203C59.0306 10.6659 58.7405 10.4666 58.4173 10.3366C58.0942 10.2065 57.7461 10.1491 57.3979 10.1682H55.9839V15.5064Z"
        fill="white"
      />
      <path
        d="M62.373 13.7482C62.3374 13.3791 62.3799 13.0067 62.4979 12.6549C62.6159 12.3031 62.8066 11.9796 63.0579 11.7052C63.3092 11.4308 63.6156 11.2116 63.9573 11.0616C64.299 10.9116 64.6685 10.8341 65.0422 10.8341C65.4158 10.8341 65.7854 10.9116 66.1271 11.0616C66.4688 11.2116 66.7751 11.4308 67.0264 11.7052C67.2777 11.9796 67.4685 12.3031 67.5864 12.6549C67.7044 13.0067 67.7469 13.3791 67.7114 13.7482C67.7476 14.1177 67.7056 14.4906 67.5879 14.843C67.4703 15.1954 67.2797 15.5194 67.0283 15.7944C66.7769 16.0693 66.4703 16.289 66.1283 16.4393C65.7862 16.5897 65.4163 16.6673 65.0422 16.6673C64.6681 16.6673 64.2981 16.5897 63.9561 16.4393C63.614 16.289 63.3074 16.0693 63.0561 15.7944C62.8047 15.5194 62.614 15.1954 62.4964 14.843C62.3788 14.4906 62.3367 14.1177 62.373 13.7482ZM66.5624 13.7482C66.5624 12.5308 66.0113 11.8188 65.044 11.8188C64.0731 11.8188 63.5269 12.5308 63.5269 13.7482C63.5269 14.9754 64.0731 15.6819 65.044 15.6819C66.0113 15.6819 66.5624 14.9705 66.5624 13.7482H66.5624Z"
        fill="white"
      />
      <path
        d="M74.8076 16.5588H73.6489L72.4791 12.4223H72.3907L71.2258 16.5588H70.0781L68.5179 10.9423H69.6509L70.6648 15.228H70.7483L71.912 10.9423H72.9836L74.1473 15.228H74.2356L75.2446 10.9423H76.3616L74.8076 16.5588Z"
        fill="white"
      />
      <path
        d="M77.6738 10.9423H78.7491V11.8345H78.8326C78.9742 11.5141 79.213 11.2454 79.5158 11.0661C79.8185 10.8867 80.1702 10.8055 80.5216 10.8339C80.797 10.8134 81.0736 10.8546 81.3307 10.9545C81.5879 11.0544 81.8191 11.2104 82.0073 11.411C82.1954 11.6116 82.3357 11.8517 82.4176 12.1134C82.4996 12.3751 82.5211 12.6518 82.4807 12.9229V16.5587H81.3637V13.2012C81.3637 12.2986 80.9684 11.8498 80.1423 11.8498C79.9553 11.8411 79.7687 11.8727 79.5952 11.9423C79.4216 12.012 79.2653 12.118 79.137 12.2532C79.0086 12.3884 78.9112 12.5495 78.8515 12.7256C78.7917 12.9016 78.771 13.0884 78.7908 13.2731V16.5588H77.6738L77.6738 10.9423Z"
        fill="white"
      />
      <path
        d="M84.2604 8.74985H85.3774V16.5588H84.2604V8.74985Z"
        fill="white"
      />
      <path
        d="M86.9302 13.7481C86.8946 13.3789 86.9372 13.0065 87.0552 12.6547C87.1732 12.3029 87.364 11.9794 87.6153 11.705C87.8667 11.4306 88.173 11.2114 88.5147 11.0613C88.8565 10.9113 89.226 10.8338 89.5997 10.8338C89.9734 10.8338 90.3429 10.9113 90.6847 11.0613C91.0264 11.2114 91.3327 11.4306 91.5841 11.705C91.8354 11.9794 92.0262 12.3029 92.1442 12.6547C92.2622 13.0065 92.3048 13.3789 92.2692 13.7481C92.3054 14.1176 92.2633 14.4905 92.1457 14.8429C92.028 15.1953 91.8373 15.5193 91.5859 15.7942C91.3345 16.0691 91.0279 16.2888 90.6858 16.4392C90.3438 16.5895 89.9738 16.6672 89.5997 16.6672C89.2256 16.6672 88.8556 16.5895 88.5136 16.4392C88.1715 16.2888 87.8649 16.0691 87.6135 15.7942C87.3621 15.5193 87.1714 15.1953 87.0537 14.8429C86.9361 14.4905 86.894 14.1176 86.9302 13.7481ZM91.1196 13.7481C91.1196 12.5306 90.5685 11.8187 89.6012 11.8187C88.6303 11.8187 88.0841 12.5306 88.0841 13.7481C88.0841 14.9753 88.6303 15.6817 89.6012 15.6817C90.5685 15.6817 91.1196 14.9704 91.1196 13.7481H91.1196Z"
        fill="white"
      />
      <path
        d="M93.4453 14.9703C93.4453 13.9593 94.2039 13.3765 95.5505 13.2937L97.0836 13.206V12.7212C97.0836 12.128 96.6884 11.793 95.9249 11.793C95.3013 11.793 94.8692 12.0202 94.7452 12.4173H93.6638C93.778 11.4526 94.6925 10.8338 95.9764 10.8338C97.3954 10.8338 98.1957 11.5348 98.1957 12.7212V16.5587H97.1204V15.7694H97.0321C96.8527 16.0525 96.6008 16.2833 96.3021 16.4382C96.0034 16.5932 95.6686 16.6667 95.332 16.6512C95.0944 16.6757 94.8542 16.6506 94.627 16.5774C94.3998 16.5042 94.1906 16.3845 94.0128 16.2261C93.8351 16.0678 93.6927 15.8742 93.595 15.6579C93.4972 15.4416 93.4462 15.2074 93.4453 14.9703ZM97.0836 14.4904V14.0209L95.7015 14.1086C94.922 14.1603 94.5685 14.4234 94.5685 14.9186C94.5685 15.424 95.0104 15.7182 95.618 15.7182C95.7961 15.7361 95.9759 15.7182 96.1469 15.6658C96.3178 15.6133 96.4764 15.5272 96.6131 15.4126C96.7499 15.2981 96.862 15.1574 96.9428 14.999C97.0236 14.8405 97.0715 14.6676 97.0836 14.4904Z"
        fill="white"
      />
      <path
        d="M99.6638 13.7481C99.6638 11.9734 100.583 10.8492 102.013 10.8492C102.367 10.833 102.718 10.9171 103.025 11.0916C103.333 11.2661 103.584 11.5238 103.749 11.8345H103.832V8.74985H104.949V16.5588H103.879V15.6714H103.791C103.613 15.9801 103.353 16.2346 103.04 16.4073C102.726 16.5801 102.371 16.6644 102.013 16.6513C100.573 16.6514 99.6638 15.5271 99.6638 13.7481ZM100.818 13.7481C100.818 14.9394 101.384 15.6562 102.33 15.6562C103.271 15.6562 103.853 14.9291 103.853 13.753C103.853 12.5825 103.265 11.8449 102.33 11.8449C101.39 11.8449 100.818 12.5666 100.818 13.7481H100.818Z"
        fill="white"
      />
      <path
        d="M109.571 13.7482C109.536 13.3791 109.578 13.0067 109.696 12.6549C109.814 12.3031 110.005 11.9796 110.256 11.7052C110.507 11.4308 110.814 11.2116 111.155 11.0616C111.497 10.9116 111.867 10.8341 112.24 10.8341C112.614 10.8341 112.983 10.9116 113.325 11.0616C113.667 11.2116 113.973 11.4308 114.225 11.7052C114.476 11.9796 114.667 12.3031 114.785 12.6549C114.903 13.0067 114.945 13.3791 114.909 13.7482C114.946 14.1177 114.904 14.4906 114.786 14.843C114.668 15.1954 114.478 15.5194 114.226 15.7944C113.975 16.0693 113.668 16.289 113.326 16.4393C112.984 16.5897 112.614 16.6673 112.24 16.6673C111.866 16.6673 111.496 16.5897 111.154 16.4393C110.812 16.289 110.506 16.0693 110.254 15.7944C110.003 15.5194 109.812 15.1954 109.695 14.843C109.577 14.4906 109.535 14.1177 109.571 13.7482ZM113.761 13.7482C113.761 12.5308 113.209 11.8188 112.242 11.8188C111.271 11.8188 110.725 12.5308 110.725 13.7482C110.725 14.9754 111.271 15.6819 112.242 15.6819C113.209 15.6819 113.761 14.9705 113.761 13.7482Z"
        fill="white"
      />
      <path
        d="M116.408 10.9423H117.483V11.8345H117.567C117.708 11.5141 117.947 11.2454 118.25 11.0661C118.553 10.8867 118.904 10.8055 119.256 10.8339C119.531 10.8134 119.808 10.8546 120.065 10.9545C120.322 11.0544 120.553 11.2104 120.742 11.411C120.93 11.6116 121.07 11.8517 121.152 12.1134C121.234 12.3751 121.255 12.6518 121.215 12.9229V16.5587H120.098V13.2012C120.098 12.2986 119.703 11.8498 118.877 11.8498C118.69 11.8411 118.503 11.8727 118.329 11.9423C118.156 12.012 118 12.118 117.871 12.2532C117.743 12.3884 117.645 12.5495 117.586 12.7256C117.526 12.9016 117.505 13.0884 117.525 13.2731V16.5588H116.408V10.9423Z"
        fill="white"
      />
      <path
        d="M127.527 9.54404V10.9679H128.753V11.9016H127.527V14.7896C127.527 15.3779 127.771 15.6355 128.327 15.6355C128.469 15.6351 128.612 15.6265 128.753 15.6099V16.5332C128.552 16.5688 128.349 16.5878 128.145 16.5899C126.903 16.5899 126.408 16.1562 126.408 15.0734V11.9015H125.51V10.9679H126.408V9.54404H127.527Z"
        fill="white"
      />
      <path
        d="M130.279 8.74985H131.386V11.8449H131.474C131.623 11.5215 131.868 11.2513 132.177 11.0713C132.486 10.8912 132.843 10.8101 133.2 10.8388C133.474 10.824 133.748 10.8694 134.002 10.9717C134.256 11.0741 134.485 11.2308 134.671 11.4308C134.857 11.6308 134.996 11.8691 135.079 12.1287C135.162 12.3883 135.186 12.6628 135.149 12.9326V16.5588H134.031V13.2061C134.031 12.309 133.61 11.8547 132.821 11.8547C132.629 11.839 132.436 11.8652 132.255 11.9314C132.074 11.9976 131.91 12.1021 131.775 12.2378C131.639 12.3734 131.535 12.5368 131.469 12.7166C131.404 12.8964 131.379 13.0882 131.396 13.2786V16.5588H130.279L130.279 8.74985Z"
        fill="white"
      />
      <path
        d="M141.663 15.0422C141.511 15.5555 141.182 15.9995 140.734 16.2965C140.285 16.5936 139.746 16.7247 139.21 16.667C138.837 16.6768 138.467 16.6059 138.124 16.4594C137.781 16.3128 137.475 16.094 137.226 15.8181C136.978 15.5423 136.792 15.2161 136.683 14.8621C136.574 14.5081 136.544 14.1348 136.595 13.7681C136.546 13.4003 136.576 13.0262 136.685 12.6711C136.794 12.3161 136.978 11.9883 137.226 11.7101C137.473 11.4318 137.778 11.2096 138.119 11.0584C138.461 10.9072 138.831 10.8306 139.205 10.8338C140.78 10.8338 141.73 11.9015 141.73 13.6651V14.0519H137.733V14.114C137.716 14.3201 137.742 14.5276 137.81 14.7231C137.878 14.9186 137.986 15.0979 138.128 15.2494C138.27 15.4008 138.443 15.5212 138.634 15.6028C138.826 15.6844 139.032 15.7253 139.241 15.723C139.508 15.7549 139.779 15.7071 140.018 15.5859C140.258 15.4646 140.456 15.2754 140.587 15.0421L141.663 15.0422ZM137.733 13.2321H140.592C140.606 13.0436 140.58 12.8543 140.516 12.6763C140.452 12.4983 140.351 12.3355 140.22 12.1985C140.089 12.0615 139.93 11.9532 139.754 11.8807C139.579 11.8081 139.389 11.7729 139.199 11.7772C139.006 11.7748 138.814 11.8108 138.635 11.883C138.456 11.9553 138.294 12.0624 138.157 12.198C138.02 12.3336 137.913 12.495 137.84 12.6726C137.767 12.8502 137.731 13.0404 137.733 13.2321H137.733Z"
        fill="white"
      />
    </svg>
  );
};
