import squareButtonIcon from 'assets/blue-button.webp';
import rabbitIcon from 'assets/colored-rabbit.webp';
import heartIcon from 'assets/green-heart.webp';
import pinIcon from 'assets/grey-pin.webp';
import roundButtonIcon from 'assets/pink-button.webp';
import yarnIcon from 'assets/purple-yarn.webp';

export const slides = [
  {
    src: squareButtonIcon,
    alt: 'PDF project text',
    text: 'PDF project',
    textColor: 'text-textBlue',
  },
  {
    src: rabbitIcon,
    alt: 'Crochet patterns text',
    text: 'Crochet patterns',
    textColor: 'text-gold',
  },
  {
    src: heartIcon,
    alt: 'Row counter text',
    text: 'Row counter',
    textColor: 'text-textGreen',
  },
  {
    src: yarnIcon,
    alt: 'Inventory text',
    text: 'Inventory',
    textColor: 'text-textPurple',
  },
  {
    src: pinIcon,
    alt: 'Abbreviations text',
    text: 'Abbreviations',
    textColor: 'text-textLightGrey',
  },
  {
    src: roundButtonIcon,
    alt: 'Shopping list text',
    text: 'Shopping list',
    textColor: 'text-textPink',
  },
];
