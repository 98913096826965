import { Content } from 'components/Content';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { ScreenClassProvider } from 'react-grid-system';

function App() {
  return (
    <ScreenClassProvider>
      <Header />
      <Content />
      <Footer />
    </ScreenClassProvider>
  );
}

export default App;
