import { slides } from 'data/slides';
import Slider from 'react-slick';

export const SliderBlock = () => {
  return (
    <div className="mt-3 md:mt-8 overflow-hidden">
      <Slider
        vertical
        slidesToShow={1}
        speed={300}
        cssEase="ease-in"
        dots={false}
        arrows={false}
        infinite
        autoplay
      >
        {slides.map((slide) => (
          <div key={slide.src} className={`h-20 ${slide.textColor}`}>
            <p className="flex items-center font-courgette font-500 text-4xl md:text-5xl xl:text-6xl">
              {slide.text}
              <span className="-top-1 ml-4">
                <img
                  className="w-14 h-14 md:w-20 md:h-20"
                  src={slide.src}
                  alt={slide.alt}
                />
              </span>
            </p>
          </div>
        ))}
      </Slider>
    </div>
  );
};
