import teddyBearImage from 'assets/icon-teddy-bear.webp';
import { AppDownload } from 'components/AppDownload';
import { ContentContainer } from 'components/Container';

import { Logo } from 'components/Logo';

export const Footer = () => {
  return (
    <footer className="bg-purple py-12 lg:py-20 mt-24 lg:mt-36">
      <ContentContainer>
        <div className="grid text-center relative">
          <img
            className="absolute -top-32 md:-top-38 lg:-top-52 right-0 h-auto w-32 md:w-36 lg:w-56"
            src={teddyBearImage}
            alt="teddy bear toy"
          />
          <Logo />
          <p className="font-500 text-2xl lg:text-4xl lg:leading-12 mt-9">
            The first mobile app crafted <br /> specially for crocheters
          </p>
          <div className="flex justify-center mt-10">
            <AppDownload inlineButtons />
          </div>
        </div>
      </ContentContainer>
    </footer>
  );
};
