import heartImage from 'assets/icon-heart.webp';
import pinImage from 'assets/icon-pin.webp';
import rabbitImage from 'assets/icon-rabbit.webp';
import roundButtonImage from 'assets/icon-round-button.webp';
import squareButtonImage from 'assets/icon-square-button.webp';
import yarnImage from 'assets/icon-yarn.webp';

export const cards = [
    {
      src: squareButtonImage,
      alt: 'Square button blue color',
      background: 'bg-blue',
      border: 'border-blueBorder',
      textColor: 'text-blueCardText',
      title: 'PDF Projects',
      text: "Work on something you've purchased elsewhere. Input PDF file and use all the benefits of mobile row counter and utilities of an app.",
    },
    {
      src: rabbitImage,
      alt: 'Plush toy rabbit',
      background: 'bg-yellow',
      border: 'border-yellowBorder',
      textColor: 'text-yellowCardText',
      title: 'Crochet app patterns',
      text: 'Choose from more than 15 free patterns from top creators, optimized specially for mobile format.',
    },
    {
      src: heartImage,
      alt: 'Knitted heart green color',
      background: 'bg-green',
      border: 'border-greenBorder',
      textColor: 'text-greenCardText',
      title: 'Row counter',
      text: 'A simple row counter that can be with or without a pattern. Also you can create more than one counter at once.',
    },
    {
      src: yarnImage,
      alt: 'Purple yarn',
      background: 'bg-purple',
      border: 'border-purpleBorder',
      textColor: 'text-purpleCardText',
      title: 'Yarn and hook inventory',
      text: 'Thousands of yarns, colors, width, properties in the app. Build your inventory with the yarns from the manufacturers your choose.',
    },
    {
      src: roundButtonImage,
      alt: 'Rounded button pink color',
      background: 'bg-pink',
      border: 'border-pinkBorder',
      textColor: 'text-pinkCardText',
      title: 'Personal shopping list',
      text: 'Plan your upcoming projects and needed purchases, yarns, fiberfill, needles & crochets in one place, control them in one click.',
    },
    {
      src: pinImage,
      alt: 'Grey pin',
      background: 'bg-grey',
      border: 'border-greyBorder',
      textColor: 'text-greyCardText',
      title: 'Abbreviations & other tools',
      text: "Work on something you've purchased elsewhere. Input PDF file and use all the benefits of mobile row counter and utilities of an app.",
    },
  ];