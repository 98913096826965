import crochetImage from 'assets/device.webp';
import { ContentContainer } from 'components/Container';
import { SliderBlock } from 'components/Slider';

export const Hero = () => {
  return (
    <ContentContainer>
      <div className="-mt-5 lg:-mt-32">
        <div className="flex items-center flex-wrap-reverse lg:grid lg:grid-cols-2 min-h-95vh relative">
          <div className="w-full">
            <h1 className="font-600 text-primary text-4xl  md:text-5xl xl:text-6xl">
              All-in-one app for
              <br /> your daily crochet
            </h1>
            <SliderBlock />
          </div>
          <div className='md:mx-auto min-h-full min-w-full'>
            <img
              className="mb-5 max-w-full md:max-w-lg lg:max-w-full"
              src={crochetImage}
              alt="Mobile with crochet app on screen"
            />
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};
