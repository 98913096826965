interface CardEntry {
  src: string;
  alt: string;
  background: string;
  border: string;
  textColor: string;
  title: string;
  text: string;
}
interface Props {
  card: CardEntry;
}

export const Card = ({ card }: Props) => {
  return (
    <div
      className={`pt-3 pb-8 md:pb-10 px-6 ${card.background} rounded-50px border-thin ${card.border}`}
    >
      <img
        className="w-24 h-24 md:w-32 md:h-32"
        src={card.src}
        alt={card.alt}
      />
      <h3 className="text-2xl max-w-60 md:max-w-full md:text-3xl font-500">
        {card.title}
      </h3>
      <p className={`text-lg ${card.textColor} mt-3 leading-6`}>
        {card.text}
      </p>
    </div>
  );
};
