import { Cards } from 'components/Cards';
import { Hero } from 'components/Hero';
import { QrCodeBlock } from 'components/QrCodeBlock';

export const Content = () => {
  return (
    <main>
      <Hero />
      <QrCodeBlock />
      <Cards />
    </main>
  );
};
