import { useEffect } from 'react';
import { Container, ScreenClass, setConfiguration } from 'react-grid-system';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const configuration = {
  gridColumns: 12,
  gutterWidth: 40,
  containerWidths: [646, 780, 980, 1180, 1240],
  breakpoints: [576, 840, 1023, 1200, 1400],
  defaultScreenClass: 'xxl' as ScreenClass,
  maxScreenClass: 'xxl' as ScreenClass,
};

export const ContentContainer = ({ children }: Props) => {
  useEffect(() => {
    setConfiguration(configuration);
  }, []);

  return (
    <Container>
      <div className="mx-auto">{children}</div>
    </Container>
  );
};
