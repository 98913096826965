import { Card } from 'components/Cards/Card';
import { ContentContainer } from 'components/Container';
import { cards } from 'data/cards';
import { Col, Row } from 'react-grid-system';

export const Cards = () => {
  return (
    <section className="mt-20 lg:mt-36">
      <ContentContainer>
        <Row>
          <Col xs={12}>
            <h2 className="max-w-78 md:max-w-full font-500 text-3xl md:text-4xl lg:text-5xl">
              Simple crochet & knitting app
            </h2>
          </Col>
          <Col xs={12}>
            <p className="text-textGreyBlue text-lg md:text-xl max-w-812px mt-5 lg:mt-9">
              With row counter, pattern upload, yarn warehouse, shopping list
              and lots of other tools. New mobile-friendly patterns that were
              made specially for mobile devices.
            </p>
          </Col>
        </Row>
        <div className="mt-10 lg:mt-20 grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-10">
          {cards.map((card) => (
            <Card key={card.src} card={card} />
          ))}
        </div>
      </ContentContainer>
    </section>
  );
};
